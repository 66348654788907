import './App.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import MainContent from './pages/MainContent/MainContent';


const App = () => {
  return (
    <Router>
      <MainContent />
    </Router>
  );
};

export default App;
