import './Dashboard.scss';
import { dropletsStore } from '../../stores/DropletStore';
import DashboardIcon1 from '../../assets/dashboard-icon1.svg';
import DashboardIcon2 from '../../assets/dashboard-icon2.svg';
import DashboardIcon3 from '../../assets/dashboard-icon3.svg';
import DashboardIcon4 from '../../assets/dashboard-icon4.svg';
import DashboardIcon5 from '../../assets/dashboard-icon5.svg';
import DashboardIcon6 from '../../assets/dashboard-icon6.svg';
import DashboardIcon7 from '../../assets/dashboard-icon7.svg';
import ActionsImage from '../../assets/droplet-dashboard-+.png';
import ProjectImage from '../../assets/project-img.png';

const Dashboard = () => {
    return (
        <div className="dashboard-container">
            <div className="dashboard-header">
                <img src={ProjectImage} alt='project-imag' />
                <div className='project-info-container'>
                    <div className="project-title">enviroment-prod <span className='default'>default</span></div>
                    <div className="project-title">Mobile Application / Development / SpotNet app development</div>
                </div>
            </div>
            <div className="dashboard-box">
                <div className="navigation-section">
                    <div className="nav-item selected">Resources</div>
                    <div className="nav-item">Activity</div>
                    <div className="nav-item">Settings</div>
                    <hr className="nav-line" />
                    <hr className="nav-line active" />
                </div>
                <div className='section-header'>
                    DROPLETS ({dropletsStore.droplets.length})
                </div>
                <ul className="service-list">
                    {dropletsStore.droplets.map(droplet => (
                        <li key={droplet.id} className="DropletItem">
                            <span className="dot"></span>
                            <div className="droplet-info">
                                <div className="droplet-name">{droplet.name}</div>
                                <div className="droplet-details">FRA1/2GB/25GB Disk</div>
                            </div>
                            <div className="droplet-ip">192.168.1.1</div>
                            <div className='second-part'>
                                <div className="droplet-tags">Add tags</div>
                                <img className={'droplet-actions'} src={ActionsImage} alt='imagse' />
                            </div>
                            <div className="more-options">...</div>
                        </li>
                    ))}
                </ul>
                <div className='section-header'>
                    DATABASE CLUSTER ({dropletsStore.droplets.length})
                </div>
                <ul className="service-list">
                    {dropletsStore.droplets.map(droplet => (
                        <li key={droplet.id} className="DropletItem">
                            <span className="dot"></span>
                            <div className="droplet-info">
                                <div className="droplet-name">dev-SkyNet</div>
                            </div>
                            <div className="droplet-ip">Primary only</div>
                            <div className="more-options">...</div>
                        </li>
                    ))}
                </ul>

                <div className='section-header'>
                    SPACES (2)
                </div>
                <ul className="service-list">
                    {dropletsStore.droplets.slice(0, 2).map(droplet => (
                        <li key={droplet.id} className="DropletItem">
                            <span className="dot"></span>
                            <div className="droplet-info">
                                <div className="droplet-name">{droplet.name}</div>
                            </div>
                            <div className="droplet-ip">https://SkyNet-storage.com</div>
                            <div className="more-options">...</div>
                        </li>
                    ))}
                </ul>


                <div className="dashboard-footer">
                    <div className="footer-content">

                        <div className="left-content">
                            <div className="header-item">Create something new</div>
                            <div className="text-icon-container">
                                <div className='icon-container'>
                                    <img className='icon' alt='DashboardIcon1' src={DashboardIcon1} />
                                </div>
                                <div className="text-container">
                                    <div className="text-item">Spin up a load Balancer</div>
                                    <div className="text-item">Distribute traffic between multiple Droplets</div>
                                </div>
                            </div>
                            <div className="header-item">Build on what you have</div>
                            <div className="items-container">
                                <div className="left-items">
                                    <div className="text-icon-container">
                                        <div className='icon-container'>
                                            <img className='icon' alt='DashboardIcon2' src={DashboardIcon2} />
                                        </div>
                                        <div className="text-container">
                                            <div className="text-item">Add a disk to your Droplet</div>
                                            <div className="text-item">Create a block storage volume</div>
                                        </div>
                                    </div>
                                    <div className="text-icon-container">
                                        <div className='icon-container'>
                                            <img className='icon' alt='DashboardIcon3' src={DashboardIcon3} />
                                        </div>
                                        <div className="text-container">
                                            <div className="text-item">Take a snapshot</div>
                                            <div className="text-item">Make on-demand copies of Droplets</div>
                                        </div>
                                    </div>
                                    <div className="text-icon-container">
                                        <div className='icon-container'>
                                            <img className='icon' alt='DashboardIcon4' src={DashboardIcon4} />
                                        </div>
                                        <div className="text-container">
                                            <div className="text-item">Start using Reserved IPs</div>
                                            <div className="text-item">Redirect Droplet traffic quickly</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="right-items">
                                    <div className="text-icon-container">
                                        <div className='icon-container'>
                                            <img className='icon' alt='DashboardIcon5' src={DashboardIcon5} />
                                        </div>
                                        <div className="text-container">
                                            <div className="text-item">Manage DNS on SkyNet</div>
                                            <div className="text-item">Manage DNS and resources in one place</div>
                                        </div>
                                    </div>
                                    <div className="text-icon-container">
                                        <div className='icon-container'>
                                            <img className='icon' alt='DashboardIcon6' src={DashboardIcon6} />
                                        </div>
                                        <div className="text-container">
                                            <div className="text-item">Secure your Droplets</div>
                                            <div className="text-item">Create a cloud firewall</div>
                                        </div>
                                    </div>
                                    <div className="text-icon-container">
                                        <div className='icon-container'>
                                            <img className='icon' alt='DashboardIcon7' src={DashboardIcon7} />
                                        </div>
                                        <div className="text-container">
                                            <div className="text-item">Track more Droplet metrics</div>
                                            <div className="text-item">Enable the SkyNet agent</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right-most-content">
                            <div className="header-item">Learn more</div>
                            <div className="text-item-header">Product Docs</div>
                            <div className="text-item">Technical overviews, how-tos, release notes, and support material</div>
                            <div className="text-item-header">Tutorials</div>
                            <div className="text-item">DevOps and development guidelines</div>
                            <div className="text-item-header">API & CLI Docs</div>
                            <div className="text-item">Run your resources programmatically</div>
                            <div className="text-item-header">Ask a question</div>
                            <div className="text-item">Connect, share and learn</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Dashboard;
