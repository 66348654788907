import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { dropletsStore } from '../../stores/DropletStore';
import './ViewDroplet.scss';
import ArrowDown from '../../assets/arrow-down.svg';
import { CustomGraph } from '../../components/FirstGraph/FirstGraph';
import { Modal } from '../../modals/Modal';
import ConsoleIcon from '../../assets/droplet-console.png';

export const generateRandomColor = () => {
    // Ensure at least one color component is less than 200
    let r = Math.floor(Math.random() * 200);
    let g = Math.floor(Math.random() * 200);
    let b = Math.floor(Math.random() * 200);

    // Randomly choose which component to potentially increase
    // to add more variety while avoiding very light colors
    const increase = Math.floor(Math.random() * 3);
    if (increase === 0) {
        r = Math.floor(Math.random() * 255);
    } else if (increase === 1) {
        g = Math.floor(Math.random() * 255);
    } else {
        b = Math.floor(Math.random() * 255);
    }

    return `rgba(${r}, ${g}, ${b}, 1)`;
};

const ViewDroplet = () => {
    const [activeTab, setActiveTab] = useState('graphs');
    const { dropletId } = useParams();
    const droplet = dropletsStore.droplets.find(d => d.id === parseInt(dropletId as string));

    const [isSwitchOn, setIsSwitchOn] = useState(true);

    const toggleSwitch = () => {
        setIsSwitchOn(!isSwitchOn);
    };


    if (!droplet) {
        return <div>Droplet not found</div>;
    }


    const generateData = () => {
        return Array.from({ length: 12 }, () => Math.floor(Math.random() * 50));
    };

    const lineLegends = {
        bandwidth: [
            { color: generateRandomColor(), name: "private - inbound", data: generateData() },
            { color: generateRandomColor(), name: "public - inbound", data: generateData() },
            { color: generateRandomColor(), name: "private - outbound", data: generateData() },
            { color: generateRandomColor(), name: "public - outbound", data: generateData() }
        ],
        cpuUsage: [
            { color: generateRandomColor(), name: "sys", data: generateData() },
            { color: generateRandomColor(), name: "user", data: generateData() },
        ],
        diskIO: [
            { color: generateRandomColor(), name: "read", data: generateData() },
            { color: generateRandomColor(), name: "write", data: generateData() },
        ]
    };

    const renderContent = () => {
        switch (activeTab) {
            case 'graphs':
                return <GraphsContent lineLegends={lineLegends} />;
            case 'access':
                return <AccessContent />;
            // case 'power':
            //     return <PowerContent />;
            // case 'backups':
            //     return <BackupsContent />;
            default:
                return <AccessContent />;
        }
    };


    const tabs = [
        'Graphs', 'Access', 'Power', 'Volumes', 'Resize', 'Networking',
        'Backups', 'Snapshots', 'Kernel', 'History', 'Destroy', 'Tags', 'Recovery'
    ];

    return (
        <div className="view-droplet-container">
            <div className="droplet-header">
                <h1>{droplet.name}</h1>
                <div className='switch-subheader-container'>
                    <p className="droplet-subtext">in enviroment-prod / 2 GB Memory / 50 GB Disk / FRA1 - AlmaLinux 9</p>
                    <ToggleSwitch isOn={isSwitchOn} onToggle={toggleSwitch} />
                </div>
            </div>
            <div className="droplet-details">
                <p>ipv4: 64.225.96.83</p>
                <p className="enable-now">ipv6: <span>Enable now</span></p>
                <p>Private IP: 10.114.0.6</p>
                <p className="enable-now">Reserved IP: <span>Enable now</span></p>
                <p className="console-text">Console: <img src={ConsoleIcon} alt="Console Icon" /></p>
            </div>

            <div className="main-content">
                <div className="tabs">
                    {tabs.map(tab => (
                        <div key={tab}
                            className={`tab-btn ${activeTab === tab.toLowerCase() ? 'active-tab' : ''}`}
                            onClick={() => setActiveTab(tab.toLowerCase())}>{tab}</div>
                    ))}
                </div>
                <div className="tab-content">
                    {renderContent()}
                </div>
            </div>
        </div>
    );
};

const GraphsContent = ({ lineLegends }: any) => {


    return (
    <div style={{ marginBottom: 50 }}>
        <div>Learn how to update this Droplet for new metrics.</div>
        <div className="select-period">
            <div className="select-content">
                <span className="select-text">Select period</span>
                <span className="period-value">1 Hour</span>
            </div>
            <img src={ArrowDown} alt="Select" />
        </div>
        <CustomGraph
                label="Bandwidth"
                lineLegends={lineLegends?.bandwidth}
            />
            <CustomGraph
                label="CPU Usage"
                lineLegends={lineLegends?.cpuUsage}
            />
            <CustomGraph
                label="Disk I/O"
                lineLegends={lineLegends?.diskIO}
            />

        </div>
    )
};

const ToggleSwitch = ({ isOn, onToggle }: any) => {
    return (
        <div className={`toggle-switch ${isOn ? 'switch-on' : 'switch-off'}`} onClick={onToggle}>
            <div className="switch-handle"></div>
            <span className="switch-text">{isOn ? 'ON' : 'OFF'}</span>
        </div>
    );
};



const AccessContent = () => {

    const [isModalOpen, setModalOpen] = useState(false);
    const [commands, setCommands] = useState<string[]>([]);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const [isConnecting, setIsConnecting] = useState(true);
    const handleEnterPress = (event: any) => {
        if (event.key === 'Enter') {
            const newCommand = `${event.target.value}`;
            setCommands([...commands, newCommand]);
            event.target.value = ''; // Clear the input field
        }
    };


    useEffect(() => {
        if (isModalOpen) {
            // Set 'Connecting...' each time the modal opens
            setIsConnecting(true);

            // After 1 second, switch to the terminal input
            const timer = setTimeout(() => {
                setIsConnecting(false);
            }, 1000);

            return () => clearTimeout(timer); // Clear timeout if the modal closes early
        }
    }, [isModalOpen]);

    return (
        <div className="access-container">
            <div className="access-box">
                <h2>Droplet Console</h2>
                <p>Use the Droplet Console for native-like terminal access to your Droplet from your browser. Here is the list of supported OSes for the new console.</p>
                <div className="flex-container">
                    <div className="login-info">
                        <span>Log in as...</span>
                        <input
                            className="login-info-input"
                            type="text"
                            defaultValue={'root'}
                        />
                    </div>
                    <button className="launch-btn" onClick={openModal}>Launch Droplet Console</button>
                </div>
            </div>

            <div className="access-box">
                <h2>Recovery Console</h2>
                <p>Use the Recovery Console if you need to use the recovery ISO or you can’t connect to your Droplet with the Droplet Console. To use the recovery console, you must enable password authentication. If necessary, you can reset your root password below.</p>
                <button className="recovery-btn" onClick={openModal}>Launch Recovery Console</button>
            </div>
            <div className="access-box">
                <h2>Reset root password</h2>
                <p>Clicking Reset Root Password below will immediately shut down your Droplet and set a new root password.</p>
                <p>The new root password will be emailed to you within a few minutes. If the email doesn’t arrive or the new password doesn’t work, try using the <span className="highlight-text">recovery environment</span>.</p>
                <p>Do you wish to proceed?</p>
                <button className="recovery-btn" onClick={openModal}>Reset Root Password</button>
            </div>

            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <div className="terminal-lookalike">
                    {isConnecting ? (
                        <p className="connecting-text">Connecting...</p>
                    ) : (
                        <>
                            <div className="terminal-input-line">
                                <div>
                                    {commands.map((cmd, index) => (
                                        <div key={index} className="terminal-output">
                                            <span>{cmd}</span>
                                        </div>
                                    ))}
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <span className="terminal-prompt">[root@prod-api-spotnet spotnet-server]#</span>
                                    <input
                                        className="terminal-input"
                                        type="text"
                                        placeholder="Type your command..."
                                        onKeyDown={handleEnterPress}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </Modal>

        </div>
    );
};


const PowerContent = () => (
    <div>
        <h2>Power</h2>
        <button onClick={() => alert('Turning off droplet...')}>Turn Off Droplet</button>
    </div>
);

const BackupsContent = () => (
    <div>
        <h2>Backups</h2>
        <button onClick={() => alert('Disabling backups...')}>Disable Backups</button>
        <ul>
            <li>Backup 1 - Date: 2023-01-01</li>
            <li>Backup 2 - Date: 2023-01-15</li>
            <li>Backup 3 - Date: 2023-02-01</li>
            {/* Dummy list of backups */}
        </ul>
    </div>
);

export default ViewDroplet;

