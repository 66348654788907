import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './LeftMenu.scss';
import SpotNetLogo from '../../assets/logo-spotnet-menu.png';
import ArrowDown from '../../assets/arrow-down.svg';
import CloudWaysIcon from '../../assets/cloudways.svg';
import ProductIcon from '../../assets/Product-menu.svg';
import MarketplaceIcon from '../../assets/marketplace.svg';
import WhatsNewIcon from '../../assets/whats-new.svg';

const LeftMenu = () => {
    const location = useLocation();
    const [projectsOpen, setProjectsOpen] = useState(false);
    const [manageOpen, setManageOpen] = useState(false);

    const toggleProjects = () => {
        setProjectsOpen(!projectsOpen);
    };

    const toggleManage = () => {
        setManageOpen(!manageOpen);
    };

    const isEnvironmentSelected = (env: any) => {
        return location.pathname === `/env${env}`;
    };

    return (
        <div className="LeftMenu">
            <div className="menuSection">
                <div className="logo">
                    <img src={SpotNetLogo} alt="IconPlaceholder" className="IconPlaceholder" />
                </div>
                <div className="menuHeader" onClick={toggleProjects}>
                    <span className='projects'>Projects</span>
                    <span className={`arrow ${projectsOpen ? 'open' : ''}`}>
                        <img src={ArrowDown} alt="ArrowDown" className="ArrowDown whiteArrow" />


                    </span>
                </div>
                {projectsOpen && (
                    <ul className='list'>
                        <li className={`environment-item ${isEnvironmentSelected(1) ? 'selected' : ''}`}>
                            <Link to="/env1">enviroment-prod</Link>
                        </li>
                        <li className={`environment-item ${isEnvironmentSelected(2) ? 'selected' : ''}`}>
                            <Link to="/env2">enviroment-dev</Link>
                        </li>
                        <li className='new-projects'>+ <span>New Project</span></li>
                    </ul>
                )}
            </div>
            <div className="hr-container">
                <hr />
            </div>            <div className="menuSection">
                <div className="menuHeader" onClick={toggleManage}>
                    <span className='manage'>Manage</span>
                    <span className={`arrow ${manageOpen ? 'open' : ''}`}>
                        <img src={ArrowDown} alt="ArrowDown" className="ArrowDown whiteArrow" />
                    </span>
                </div>
                {manageOpen && (
                    <ul className='list'>
                        <li><Link to="/droplets">Apps</Link></li>
                        <li><Link to="/droplets">Droplets</Link></li>
                        <li><Link to="/droplets">Functions</Link></li>
                        <li><Link to="/droplets">Kubernetes</Link></li>
                        <li><Link to="/droplets">Databases</Link></li>
                        <li><Link to="/droplets">Spaces Object Storage</Link></li>
                        <li><Link to="/droplets">Container Registry</Link></li>
                        <li><Link to="/droplets">Images</Link></li>
                        <li><Link to="/droplets">Networking</Link></li>
                        <li><Link to="/droplets">Monitoring</Link></li>
                        <li><Link to="/droplets">Add-Ons</Link></li>
                    </ul>
                )}
            </div>
            <div className="hr-container">
                <hr />
            </div>
            <div className="menuSection">
                <ul>
                    <li><Link to="/billing">Billing</Link></li>
                    <li><Link to="/billing">Support</Link></li>
                    <li><Link to="/billing">Settings</Link></li>
                    <li><Link to="/billing">API</Link></li>
                </ul>
            </div>
            <div className="hr-container">
                <hr />
            </div>
            <div className="menuSection">
                <ul>
                    <li>
                        <Link to="/billing">
                            <img src={CloudWaysIcon} alt="CloudWays Icon" className="menu-icon" />
                            Cloudways
                        </Link>
                    </li>
                    <li>
                        <Link to="/billing">
                            <img src={MarketplaceIcon} alt="Marketplace Icon" className="menu-icon" />
                            Marketplace
                        </Link>
                    </li>
                    <li>
                        <Link to="/billing">
                            <img src={ProductIcon} alt="Product Docs Icon" className="menu-icon" />
                            Product Docs
                        </Link>
                    </li>
                    <li>
                        <Link to="/billing">
                            <img src={WhatsNewIcon} alt="What's New Icon" className="menu-icon" />
                            What's New
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default LeftMenu;
