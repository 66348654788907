import './Billing.scss';
import ArrowDown from '../../assets/arrow-down.svg';
import PayPalImage from '../../assets/paypal-logo.png';
import BillingLogo from '../../assets/logo-billing.png';
import MasterCardImage from '../../assets/mastercard.png';

const BillingPage = () => {
    const currentTime = new Date().toLocaleString('en-US', {
        year: 'numeric', month: 'long', day: 'numeric',
        hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false
    });

    return (
        <div className='container'>
            <div className='header-section'>
                <p className='update-info'>
                    Note: Information on this page is updated daily. Last updated {currentTime} UTC
                </p>
                <div className='flex-container'>
                    <h1>Billing</h1>
                    <div>
                        <span className='billing-info'>How billing works</span>
                        <button className='payment-button'>Make Payment</button>
                    </div>
                </div>
                <hr />
            </div>

            <div className='estimate-section'>
                <h2 className='estimate-title'>Estimated Due</h2>
                <p className='estimate-description'>
                    This is an estimate of the amount you own based on your current month-to-date usage after credits & prepayments.
                </p>
                <p className='amount-due'>$504.20</p>
                <div className='payment-details'>
                    <div className='detail-item'>
                        <span>January 1, 2024</span>
                        <span>Payment due</span>
                    </div>
                    <div className='detail-item'>
                        <span>$0.00</span>
                        <span>Prepayments</span>
                    </div>
                    <div className='detail-item'>
                        <span>$504.20</span>
                        <span>Total usage</span>
                    </div>
                    <div className='detail-item'>
                        <span>"$504.20"</span>
                        <span>Estimated due</span>
                    </div>
                </div>
            </div>
            <div className='summary-section'>
                <div className='summary-header'>
                    <span className='date'>Month-to-date Summary December 1-08, 2024</span>
                    <span className='download-options'>Download: <span className='orange-text'>PDF</span> <span className='orange-text'> - CSV</span></span>
                </div>
                <div className='sub-header'>
                    <span>These charges are factored into your account balance.</span>
                    <span className='orange-text'>Expand All</span>
                </div>
                <div className='services-list'>
                    {/* Dynamically create service items, with unique prices */}
                    {[
                        { name: 'Droplet Backups (1)', price: '$150.15' },
                        { name: 'Droplets (2)', price: '$123.20' },
                        { name: 'Managed Databases (4)', price: '$200.35' },
                        { name: 'Spaces (1)', price: '$30.50' }
                    ].map((service, index) => (
                        <div className='service-item' key={index}>
                            <div className='item-left'>
                                <img src={ArrowDown} className="arrow-icon" alt="arrow" />
                                <span className='service-name'>{service.name}</span>
                            </div>
                            <span className='service-action orange-text'>View {service.name}</span>
                            <span className='amount'>{service.price}</span>
                        </div>

                    ))}
                    <div className='total'>
                        <span>Total</span>
                        <span className='amount'>$504.20</span>
                    </div>
                </div>

            </div>
            <div className='plan-section'>
                <div className='left-content'>
                    <h2>Your Plan</h2>
                    <div className='plan-details'>
                        <div>
                            <div className='bold-text'>CURRENT</div>
                            <div>Starter</div>
                        </div>
                        <div>
                            <div className='bold-text'>PLAN COST</div>
                            <div>$0/month</div>
                        </div>
                    </div>
                    <div className='support-info'>
                        <span>Have questions around support plan billing?</span>
                        <span className='orange-text'>Check out our support pricing FAQ</span>
                    </div>
                </div>
                <div className='right-content'>
                    <button className='edit-plan-button'>Edit Plan</button>
                </div>
            </div>

            <div className='alerts-section'>
                <div className='left-content'>
                    <h2 className='orange-header'>Billing Alerts</h2>
                    <p className='alert-description'>
                        Set up automated billing alerts to receive emails when a specified usage amount is reached for spend across your entire team.
                    </p>
                </div>
                <div className='right-content'>
                    <button className='add-alert-button'>Add Alert</button>
                </div>
            </div>

            <div className='payment-methods-section'>
                <h2>Payment methods</h2>
                <div className='orange-container'>
                    <div className='text-paypal-container'>
                        <div className='paypal-info'>
                            <h3>SkyNet now supports PayPal automated payments.</h3>
                            <p>Store PayPal as a payment method and SkyNet will retrieve your billed amount
                                automatically at the end of each billing cycle. You never have to worry about paying your
                                bills on time once you have it set up.</p>
                            <img src={PayPalImage} alt="PayPal" />
                        </div>
                    </div>
                    <div className='billing-logo-container'>
                        <img src={BillingLogo} alt="Billing Logo" className="billing-logo" />
                    </div>
                </div>
                <div className='payment-option'>
                    <span>DEFAULT</span>
                    <div className='payment-card'>
                        <div className='card-info'>
                            <img src={MasterCardImage} alt="MasterCard" />
                            <span>0231</span>
                        </div>
                        <span>Expires 10/2029</span>
                    </div>
                </div>
                <div className='payment-option'>
                    <span>BACKUP</span>
                    <div className='payment-card'>
                        <span>Add a payment method</span>
                    </div>
                </div>
            </div>

            <div className='billing-settings-section'>
                <h2 className='orange-header'>Billing Settings</h2>
                <div className='settings-info'>
                    <div className='address-info'>
                        <div className='bold-text'>ADDRESS</div>
                        <div>This address appears on your monthly invoice and should be the legal address of your home or business</div>
                    </div>
                    <div className='tax-info'>
                        <div>
                            <div className='bold-text'>TAX LOCATION</div>
                            <div>Taxes are not collected for your location at this time.</div>
                        </div>
                        <button className='settings-button'>Edit Address</button>
                    </div>
                    <div className='tax-text'>
                        <p>Your tax location determines the taxes that are applied to your bill.</p>
                        <p className='orange-text'>How do I correct my tax location?</p>
                    </div>
                </div>
            </div>

            <div className='promo-code-section'>
                <h2>Promo Code</h2>
                <p>If you have a promo code, please enter it below to receive your credit.</p>
                <p className='orange-text'>More about promo codes</p>
                <div className='promo-input-container'>
                    <input type='text' placeholder='Promo code' className='promo-input' />
                    <button className='apply-button'>Apply</button>
                </div>
            </div>

            {/* <hr className='orange-hr' /> */}
            <h2 className='billing-history-header'>Billing history</h2>
            <div className='billing-history'>
                <div className='table'>
                    <div className='table-header'>
                        <div>Date</div>
                        <div>Description</div>
                        <div>Amount</div>
                        <div></div>
                    </div>
                    {[
                        { date: 'December 01, 2024', description: 'Payment (MasterCard 7196)', amount: '-$504.33' },
                        { date: 'November 30, 2024', description: 'Invoice for November 2024', amount: '$504.33', isInvoice: true },
                        { date: 'December 30, 2024', description: 'Payment (MasterCard 7196)', amount: '-$607.33', isInvoice: true },
                        { date: 'December 30, 2024', description: 'Invoice for December 2024', amount: '$607.33', isInvoice: true },
                    ].map((item, index) => (
                        <div className='table-row' key={index}>
                            <div>{item.date}</div>
                            <div className={item.isInvoice ? 'orange-text' : ''}>{item.description}</div>
                            <div>{item.amount}</div>
                            <div className='download-options'>Download: <span className='orange-text'>PDF - CSV</span></div>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    );
}

export default BillingPage;
