import { makeAutoObservable } from 'mobx';

interface Droplet {
    id: number;
    name: string;
    memory: number; // in MB
    vcpus: number;
    disk: number; // in GB
    locked: boolean;
    status: 'new' | 'active' | 'off' | 'archive';
    created_at: string; // ISO date string
    region: {
        slug: string;
        name: string;
        sizes: string[];
        features: string[];
        available: boolean;
    };
    image: {
        id: number;
        name: string;
        distribution: string;
        slug?: string;
        public: boolean;
        regions: string[];
        created_at: string; // ISO date string
    };
    size_slug: string;
    networks: {
        v4: Array<{
            ip_address: string;
            netmask: string;
            gateway: string;
            type: 'public' | 'private';
        }>;
        v6: Array<{
            ip_address: string;
            netmask: number;
            gateway: string;
            type: 'public' | 'private';
        }>;
    };
    tags: string[];
    // Additional properties can be added as needed
}


class DropletsStore {
    droplets: Droplet[] = [];

    constructor() {
        makeAutoObservable(this);
        this.droplets = [
            {
                id: 1,
                name: 'enviroment-dev',
                memory: 2048,
                vcpus: 2,
                disk: 50,
                locked: false,
                status: 'active',
                created_at: '4 months ago', // In a real app, use actual date and format it
                region: {
                    slug: 'nyc1',
                    name: 'New York 1',
                    sizes: [],
                    features: [],
                    available: true
                },
                image: {
                    id: 123456,
                    name: 'Ubuntu',
                    distribution: 'Ubuntu',
                    slug: 'ubuntu',
                    public: true,
                    regions: ['nyc1'],
                    created_at: '4 months ago'
                },
                size_slug: 's-1vcpu-2gb',
                networks: {
                    v4: [{ ip_address: '192.168.1.1', netmask: '255.255.255.0', gateway: '192.168.1.1', type: 'public' }],
                    v6: []
                },
                tags: []
            },
            {
                id: 1,
                name: 'enviroment-prod',
                memory: 2048,
                vcpus: 2,
                disk: 50,
                locked: false,
                status: 'active',
                created_at: '4 months ago', // In a real app, use actual date and format it
                region: {
                    slug: 'nyc1',
                    name: 'New York 1',
                    sizes: [],
                    features: [],
                    available: true
                },
                image: {
                    id: 123456,
                    name: 'Ubuntu',
                    distribution: 'Ubuntu',
                    slug: 'ubuntu',
                    public: true,
                    regions: ['nyc1'],
                    created_at: '4 months ago'
                },
                size_slug: 's-1vcpu-2gb',
                networks: {
                    v4: [{ ip_address: '192.168.1.1', netmask: '255.255.255.0', gateway: '192.168.1.1', type: 'public' }],
                    v6: []
                },
                tags: []
            },
        ];
    }

    addDroplet(droplet: Droplet) {
        this.droplets.push(droplet);
    }

    removeDroplet(id: number) {
        this.droplets = this.droplets.filter(droplet => droplet.id !== id);
    }

    updateDroplet(updatedDroplet: Droplet) {
        this.droplets = this.droplets.map(droplet =>
            droplet.id === updatedDroplet.id ? updatedDroplet : droplet
        );
    }

    getDropletById(id: number) {
        return this.droplets.find(droplet => droplet.id === id);
    }
}

export const dropletsStore = new DropletsStore();
