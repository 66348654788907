import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.scss';
import ArrowDown from '../../assets/arrow-down.svg';
import QuestionMark from '../../assets/question.svg';
import Bell from '../../assets/bell.svg';
import glass from '../../assets/mgnifing-glass.svg';
const Header = () => {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const handleLogout = () => {
        navigate('/signin'); // Perform any logout logic if needed
    };

    return (
        <div className="Header">
            <div className="LeftSection">
                <img src={glass} alt="IconPlaceholder" className="IconPlaceholder" />
                <input type="text" placeholder="Search by resource name or public IP (Cmd+B)" />
            </div>
            <div className="RightSection">
                <div className="QuestionMarkCircle">
                    <img src={QuestionMark} alt="QuestionMarkIcon" className="QuestionMarkIcon" />
                </div>
                <img src={Bell} alt="BellIcon" className="BellIcon" />
                <div className="Separator"></div>
                <div className="SkyNetSection">
                    <div>My app</div>
                    <span className="Price">Estimated costs: $504.20</span>
                </div>
                <div className="AvatarSection" onClick={toggleModal}>
                    <div className="SNIcon">SN</div>
                    <img src={ArrowDown} alt="Arrow Down" className="ArrowDownIcon" />
                </div>
                {showModal && (
                    <div className="Modal">
                        <div className="ModalItem" onClick={handleLogout}>Logout</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Header;
