import React, { useRef, useEffect, useState } from 'react';
import Chart from 'chart.js/auto';
import { ChartData, ChartOptions } from 'chart.js';
import './FirstGraph.scss';

interface LineLegend {
    name: string;
    color: string;
    data: any
}

interface CustomGraphProps {
    label: string;
    lineLegends: LineLegend[];
}

export const CustomGraph: React.FC<CustomGraphProps> = ({ label, lineLegends }) => {
    console.log(`🚀 ~ file: FirstGraph.tsx:18 ~ lineLegends:`, lineLegends);
    const chartRef = useRef<HTMLCanvasElement>(null);
    const chartInstanceRef = useRef<Chart | null>(null);
    const [datasetVisibility, setDatasetVisibility] = useState(lineLegends.map(() => true));

    useEffect(() => {
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
            chartInstanceRef.current = null;
        }

        if (chartRef.current) {
            const ctx = chartRef.current.getContext('2d');
            if (ctx) {
                const now = new Date();
                const labels = Array.from({ length: 12 }, (_, i) => {
                    const time = new Date(now.getTime() - (11 - i) * 5 * 60000);
                    return `${time.getHours()}:${time.getMinutes().toString().padStart(2, '0')}`;
                });

                const datasets = lineLegends.map((legend, index) => ({
                    label: legend.name,
                    data: legend.data,
                    backgroundColor: legend.color,
                    borderColor: legend.color,
                    borderWidth: 1,
                    pointRadius: 3,
                    hidden: !datasetVisibility[index], // Set visibility based on state
                }));

                const chartData: ChartData<'line'> = {
                    labels: labels,
                    datasets: datasets
                };

                const chartOptions: ChartOptions<'line'> = {
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                callback: (value) => `${value} kb/s`
                            },
                            max: 50
                        }
                    },
                    plugins: {
                        legend: {
                            display: false, // Disable the default legend
                        },
                        title: {
                            display: true,
                            text: label,
                            align: 'start',
                            position: 'top',
                            font: {
                                size: 16,
                                weight: 'bold',
                            }
                        }
                    }
                };

                const chartInstance = new Chart(ctx, {
                    type: 'line',
                    data: chartData,
                    options: chartOptions
                });
                chartInstanceRef.current = chartInstance;
            }
        }

        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, [lineLegends, datasetVisibility, label]);

    const toggleDataset = (index: number) => {
        const newVisibility = [...datasetVisibility];
        newVisibility[index] = !newVisibility[index];
        setDatasetVisibility(newVisibility);
    };

    return (
        <div className="graph-container">
            <canvas ref={chartRef}></canvas>
            <div className="line-legends">
                {lineLegends.map((legend, index) => (
                    <div key={index} className="line-legend" onClick={() => toggleDataset(index)}>
                        <span className="legend-color" style={{ backgroundColor: legend.color }}></span>
                        <span className="legend-name">{legend.name}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};


// bar graph
// import React, { useRef, useEffect, useState } from 'react';
// import Chart from 'chart.js/auto';
// import { ChartData, ChartOptions } from 'chart.js';
// import './FirstGraph.scss';

// interface LineLegend {
//     name: string;
//     color: string;
// }

// interface CustomGraphProps {
//     label: string;
//     lineLegends: LineLegend[];
// }

// export const CustomGraph: React.FC<CustomGraphProps> = ({ label, lineLegends }) => {
//     const chartRef = useRef<HTMLCanvasElement>(null);
//     const chartInstanceRef = useRef<Chart | null>(null);

//     useEffect(() => {
//         if (chartInstanceRef.current) {
//             chartInstanceRef.current.destroy();
//             chartInstanceRef.current = null;
//         }

//         if (chartRef.current) {
//             const ctx = chartRef.current.getContext('2d');
//             if (ctx) {
//                 const now = new Date();
//                 const labels = Array.from({ length: 12 }, (_, i) => {
//                     const time = new Date(now.getTime() - (11 - i) * 5 * 60000);
//                     return `${time.getHours()}:${time.getMinutes().toString().padStart(2, '0')}`;
//                 });

//                 const datasets = lineLegends.map(legend => ({
//                     label: legend.name,
//                     data: Array.from({ length: 12 }, () => Math.floor(Math.random() * 50)),
//                     backgroundColor: legend.color, // Used as the bar color
//                 }));

//                 const chartData: ChartData<'bar'> = {
//                     labels: labels,
//                     datasets: datasets
//                 };

//                 const chartOptions: ChartOptions<'bar'> = {
//                     scales: {
//                         y: {
//                             beginAtZero: true
//                         }
//                     },
//                     plugins: {
//                         legend: {
//                             display: false
//                         },
//                         title: {
//                             display: true,
//                             text: label,
//                             align: 'start',
//                             position: 'top',
//                             padding: { top: 10 },
//                             font: {
//                                 size: 16,
//                                 weight: 'bold',
//                             }
//                         }
//                     }
//                 };

//                 const chartInstance = new Chart(ctx, {
//                     type: 'bar', // Changed to 'bar'
//                     data: chartData,
//                     options: chartOptions
//                 });
//                 chartInstanceRef.current = chartInstance;
//             }
//         }

//         return () => {
//             if (chartInstanceRef.current) {
//                 chartInstanceRef.current.destroy();
//             }
//         };
//     }, [lineLegends, label]);

//     return (
//         <div className="graph-container">
//             <canvas ref={chartRef}></canvas>
//             <div className="line-legends">
//                 {lineLegends.map((legend, index) => (
//                     <div key={index} className="line-legend">
//                         <span className="legend-color" style={{ backgroundColor: legend.color }}></span>
//                         <span className="legend-name">{legend.name}</span>
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// };

