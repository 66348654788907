import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { dropletsStore } from '../../stores/DropletStore';
import './DropletsList.scss';
import ArrowDown from '../../assets/arrow-down.svg';
import Drop from '../../assets/drop.svg';

const DropletsList = observer(() => {
    const navigate = useNavigate();

    const handleCreateDroplet = () => {
        navigate('/create-droplet');
    };

    const handleDropletClick = (dropletId: number) => {
        navigate(`/view-droplet/${dropletId}`);
    };

    return (
        <div className="page-wrapper"> {/* Wrapper with gradient background */}
        <div className="droplets-container">
                <h1>Droplets</h1>
            <div className="header-actions">
                <input type="text" placeholder="Search droplets" className="search-input" />
                <button onClick={handleCreateDroplet}>
                    <div className='create-droplet-txt'>
                        Create Droplet
                    </div>
                </button>
            </div>
            <div className="droplets-list">
                <div className="droplet-header grid-container">
                    <div>Name</div>
                    <div>IP Address</div>
                    <div>Created</div>
                        <div></div> 
                </div>
                {dropletsStore.droplets.map(droplet => (
                    <div className="droplet-item grid-container" key={droplet.id} onClick={() => handleDropletClick(droplet.id)}>
                        <div>
                            {droplet.name}
                            <br />
                            <span className="droplet-details">
                                {droplet.memory}GB / {droplet.disk}GB Disk / {droplet.region.name}
                            </span>
                        </div>
                        <div>64.225.96.83</div>
                        <div>4 month ago</div>
                        <div className="more-options-container">
                            <img src={Drop} alt="IconPlaceholder" className="drop-icon" />
                            <span>More</span>
                            <img src={ArrowDown} alt="ArrowDown" className="ArrowDown" />
                        </div>                    </div>
                ))}
            </div>
            </div>
        </div>
    );
});

export default DropletsList;
