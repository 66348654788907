import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import LeftMenu from '../../components/LeftMenu/LeftMenu';
import SignInPage from '../SignIn/SignIn';
import DropletsList from '../DropletsList/DropletsList';
import CreateDropletPage from '../CreateDroplet/CreateDroplet';
import BillingPage from '../Billing/Billing';
import ViewDroplet from '../ViewDroplet/ViewDroplet';
import Dashboard from '../Dashboard/Dashboard';
import './MainContent.scss';

const OtherPage = () => <div>Other Page Content</div>;

const MainContent = () => {
    const location = useLocation();
    const isSignInPage = location.pathname === '/signin';

    return (
        <>
            <div className="App">
                {!isSignInPage && <LeftMenu />}
                <div className="MainContentArea">
                    {!isSignInPage && <Header />}
                <div className="Content">
                    <Routes>
                            <Route path="/" element={<Navigate replace to="/signin" />} />
                        <Route path="/signin" element={<SignInPage />} />
                            <Route path="/env1" element={<Dashboard />} />
                            <Route path="/env2" element={<Dashboard />} />
                        <Route path="/other" element={<OtherPage />} />
                        <Route path="/droplets" element={<DropletsList />} />
                        <Route path="/create-droplet" element={<CreateDropletPage />} />
                        <Route path="/billing" element={<BillingPage />} />
                        <Route path="/view-droplet/:dropletId" element={<ViewDroplet />} />
                            <Route path="*" element={<Navigate to="/signin" />} />
                    </Routes>
                </div>
                </div>
            </div>
        </>
    );
};

export default MainContent;
