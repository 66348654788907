import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { dropletsStore } from '../../stores/DropletStore';
import './CreateDroplet.scss';
import amsterdamIcon from '../../assets/amsterdan.png';
import bangaloreIcon from '../../assets/bangalore.png';
import frankfurtIcon from '../../assets/fra.png';
import londonIcon from '../../assets/lndn.png';
import newYorkIcon from '../../assets/ny.png';
import sanFranciscoIcon from '../../assets/SF.png';
import IsraelIcon from '../../assets/israel.png';
import sydneyIcon from '../../assets/sydn.png';
import torontoIcon from '../../assets/toronto.png';
import arrowDownIcon from '../../assets/arrow-down.svg';
import ubuntuIcon from '../../assets/ubuntu.png';
import fedoraIcon from '../../assets/fedora.png';
import debianIcon from '../../assets/debian.png';
import centosIcon from '../../assets/centos.png';
import almaIcon from '../../assets/alma.png';
import rockyIcon from '../../assets/rocky.png';

const operatingSystems = [
    { name: 'Ubuntu', icon: ubuntuIcon },
    { name: 'Fedora', icon: fedoraIcon },
    { name: 'Debian', icon: debianIcon },
    { name: 'CentOS', icon: centosIcon },
    { name: 'AlmaLinux', icon: almaIcon },
    { name: 'Rocky Linux', icon: rockyIcon }
];

const vmOptions = [
    { id: 1, priceMonthly: "$7", priceHourly: "$0.010/hour", specs: ["1GB/1 AMD CPU", "25GB NVMe SSDs", "1000 GB transfer"] },
    { id: 2, priceMonthly: "$14", priceHourly: "$0.021/hour", specs: ["2GB/1 AMD CPU", "50GB NVMe SSDs", "2 TB transfer"] },
    { id: 3, priceMonthly: "$21", priceHourly: "$0.031/hour", specs: ["2GB/2 AMD CPU", "60GB NVMe SSDs", "3 TB transfer"] },
    { id: 4, priceMonthly: "$28", priceHourly: "$0.042/hour", specs: ["4GB/2 AMD CPU", "80GB NVMe SSDs", "4 TB transfer"] },
    { id: 5, priceMonthly: "$42", priceHourly: "$0.063/hour", specs: ["8GB/2 AMD CPU", "100GB NVMe SSDs", "5 TB transfer"] },
    { id: 6, priceMonthly: "$56", priceHourly: "$0.083/hour", specs: ["8GB/2 AMD CPU", "160GB NVMe SSDs", "5 TB transfer"] }
];

const regions = [
    { name: 'Israel', icon: IsraelIcon },
    { name: 'Amsterdam', icon: amsterdamIcon },
    { name: 'Bangalore', icon: bangaloreIcon },
    { name: 'New York', icon: newYorkIcon },
    { name: 'Frankfurt', icon: frankfurtIcon },
    { name: 'London', icon: londonIcon },
    { name: 'San Francisco', icon: sanFranciscoIcon },
    { name: 'Sydney', icon: sydneyIcon },
    { name: 'Toronto', icon: torontoIcon }
];


const CreateDropletPage = () => {
    const [memory, setMemory] = useState(4);
    const [disk, setDisk] = useState(50);
    const [region, setRegion] = useState('FRA1');
    const [distribution, setDistribution] = useState('');
    const [ipAddress, setIpAddress] = useState('');

    const [selectedMonitoring, setSelectedMonitoring] = useState(false);
    const [selectedBackups, setSelectedBackups] = useState(false);
    const [selectedDatabase, setSelectedDatabase] = useState(false);

    const [quantity, setQuantity] = useState(1);
    const [hostname, setHostname] = useState('ubuntu-s-2vcpu-4gb-amd-sfo3-01');

    // Function to handle quantity change
    const handleQuantityChange = (change: any) => {
        setQuantity(prevQuantity => Math.max(1, prevQuantity + change));
    };
    const toggleMonitoring = () => setSelectedMonitoring(!selectedMonitoring);
    const toggleBackups = () => setSelectedBackups(!selectedBackups);
    const toggleDatabase = () => setSelectedDatabase(!selectedDatabase);

    const navigate = useNavigate();
    const [selectedAuthMethod, setSelectedAuthMethod] = useState('SSH Key');
    const [selectedDCOption, setSelectedDCOption] = useState('Spotnet');


    const handleSelectChange = (event: any) => {
        setSelectedDCOption(event.target.value);
    };

    const [selectedCpuOption, setSelectedCpuOption] = useState('Regular');
    const handleAuthMethodChange = (method: any) => {
        setSelectedAuthMethod(method);
    };
    const handleCpuOptionChange = (option: any) => {
        setSelectedCpuOption(option);
    };

    const [selectedVmOption, setSelectedVmOption] = useState(1);

    const handleVmOptionSelect = (id: any) => {
        setSelectedVmOption(id);
    };

    const formatSpec = (spec: string) => {
        const boldEndIndex = spec.includes('/') ? spec.indexOf('/') + 1 : spec.includes('GB') ? spec.indexOf('GB') + 2 : spec.indexOf('TB') + 2;
        return (
            <>
                <span style={{ fontWeight: 500 }}>{spec.substring(0, boldEndIndex)}</span>
                {spec.substring(boldEndIndex)}
            </>
        );
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        // Here you should include logic to generate an ID and other missing fields
        const newDroplet = {
            id: dropletsStore.droplets.length + 1, // This is a placeholder. In a real app, IDs should be generated uniquely.
            name: hostname,
            memory,
            vcpus: 2, // This is a static value for simplicity
            disk,
            locked: false,
            status: 'active' as 'active', // Default status
            created_at: new Date().toISOString(),
            region: {
                slug: region,
                name: region,
                sizes: [],
                features: [],
                available: true
            },
            image: {
                id: Math.random(), // This is a placeholder.
                name: distribution,
                distribution,
                slug: distribution.toLowerCase(),
                public: true,
                regions: [region],
                created_at: new Date().toISOString()
            },
            size_slug: 'custom', // This is a static value for simplicity
            networks: {
                v4: [{ ip_address: ipAddress, netmask: '255.255.255.0', gateway: ipAddress, type: 'public' as 'public' }],
                v6: []
            },
            tags: []
        };
        dropletsStore.addDroplet(newDroplet);
        navigate('/droplets'); // Navigate back to the droplets list
    };

    return (
        <div className="create-droplet-container">
            <form onSubmit={handleSubmit}>
                <h1 >Create Droplet</h1>
                <hr className="section-separator" />
                <div className="region-selection-box">
                    <div className="box-header">Choose Region</div>
                    <div className="region-grid">
                        {regions.map((region, index) => (
                            <div key={index} className={`region-item ${region.name === 'Israel' ? 'selected' : ''}`}>
                                <img src={region.icon} alt={`${region.name} flag`} />
                                {region.name}
                            </div>
                        ))}
                    </div>
                </div>

                <hr className="section-separator" />

                <div className="data-center-container">
                    <div className="data-center-header">Data Center</div>
                    <div className="data-center-select">
                        <select
                            value={selectedDCOption}
                            onChange={handleSelectChange}
                            className='select'
                        >
                            <option value="Spotnet">Spotnet • Datacenter 1 • SFO1</option>
                            <option value="Ofek">Ofek • Datacenter 2 • SFO2</option>
                            <option value="Matrix">Matrix • Datacenter 3 • SFO3</option>
                            <option value="One">One1 • Datacenter 4 • SFO4</option>
                        </select>
                    </div>
                </div>

                <div className="choose-image-container">
                    <div className="data-center-header">Choose an Image</div>
                    <div className="image-navigation-section">
                        <div className="nav-item selected">OS</div>
                        <div className="nav-item">Marketplace</div>
                        <div className="nav-item">Backups Custom Images</div>
                        <hr className="nav-line" />
                        <hr className="nav-line active" />
                    </div>
                    <div className="os-selection">
                        {operatingSystems.map((os, index) => (
                            <div key={index} className={`os-item ${os.name === 'Ubuntu' ? 'ubuntu' : ''}`}>
                                <img src={os.icon} alt={`${os.name} icon`} />
                                <span>{os.name}</span>
                            </div>
                        ))}
                    </div>

                    <hr className="section-separator" />

                    <div className="cpu-options-container">
                        <div className="data-center-header">CPU Options</div>
                        <div className="cpu-options">
                            {['Regular', 'Premium Intel', 'Premium AMD'].map((option, index) => (
                                <div key={index}
                                    className={`cpu-option ${selectedCpuOption === option ? 'active' : ''}`}
                                    onClick={() => handleCpuOptionChange(option)}>
                                    <div className="radio-button">
                                        {selectedCpuOption === option && <div className="radio-selected"></div>}
                                    </div>
                                    <div className="cpu-description">
                                        <div className="cpu-type cpu-text">{option}</div>
                                        <div className="cpu-disk cpu-text">Disk type: {option === 'Regular' ? 'SSD' : 'NVMe SSD'}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="vm-options-container">
                    {vmOptions.map(option => (
                        <div key={option.id}
                            className={`vm-option ${selectedVmOption === option.id ? 'selected' : ''}`}
                            onClick={() => handleVmOptionSelect(option.id)}>
                            <div className="vm-price">
                                <span className="price-monthly">{option.priceMonthly.split('/')[0]}<span className="price-monthly-unit">/mo</span></span>
                                <span className="price-hourly">{option.priceHourly}</span>
                            </div>
                            <hr className="vm-separator" />
                            <div className="vm-specs">
                                {option.specs.map((spec, index) => (
                                    <div key={index} className="vm-spec">{formatSpec(spec)}</div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

                <hr className="section-separator" />
                <div className="additional-storage-container">
                    <div className="data-center-header">Additional Storage</div>
                    <div className="additional-storage-box">
                        <div className="add-volume-btn">Add Volume</div>
                        <div className="storage-info">
                            <div className="storage-info-bold">
                                Need more disk space? Add a volume with no manual setup.
                            </div>
                            <div className="storage-info-regular">
                                Block storage volumes add extra disk space. We automatically format and mount your volume so it’s available as soon as your Droplet is, and you can move volumes seamlessly between Droplets at any time. Think of it like a flash drive for your VM.
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="section-separator" />

                <div className="authentication-method-container">
                    <div className="authentication-header">
                        <span>Choose Authentication Method</span>
                        <div className="help-icon">?</div>
                    </div>

                    <div className="authentication-options">
                        <div className={`auth-option ${selectedAuthMethod === 'SSH Key' ? 'selected' : ''}`} onClick={() => handleAuthMethodChange('SSH Key')}>
                            <div className="radio-button">
                                {selectedAuthMethod === 'SSH Key' && <div className="radio-selected"></div>}
                            </div>
                            <div className="auth-description">
                                <div className="auth-type">SSH Key</div>
                                <div className="auth-info">Connect to your Droplet with an SSH key pair</div>
                            </div>
                        </div>

                        <div className={`auth-option ${selectedAuthMethod === 'Password' ? 'selected' : ''}`} onClick={() => handleAuthMethodChange('Password')}>
                            <div className="radio-button">
                                {selectedAuthMethod === 'Password' && <div className="radio-selected"></div>}
                            </div>
                            <div className="auth-description">
                                <div className="auth-type">Password</div>
                                <div className="auth-info">Connect to your Droplet as the “root” user via password</div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className="section-separator" />

                <div className="recommendation-container">
                    <div className="data-center-header">We recommend these options</div>

                    {/* Recommendation 1 */}
                    <div className="recommendation-option" onClick={toggleMonitoring}>
                        <div className="checkbox" >
                            {selectedMonitoring && <div className="checkbox-ticked"></div>}
                        </div>
                        <div className="recommendation-description">
                            <div className="recommendation-title">Add improved metrics monitoring and alerting (free)</div>
                            <div className="recommendation-info">Collect and graph expanded system-level metrics, track performance, and set up alerts instantly within the control panel.</div>
                        </div>
                    </div>

                    {/* Recommendation 2 */}
                    <div className="recommendation-option" onClick={toggleBackups}>
                        <div className="checkbox">
                            {selectedBackups && <div className="checkbox-ticked"></div>}
                        </div>
                        <div className="recommendation-description">
                            <div className="recommendation-title">Going to production? Enable backups (+$5.60)</div>
                            <div className="recommendation-info">Add security with weekly disk images for easy restoration, no configuration required.</div>
                        </div>
                    </div>

                    {/* Recommendation 3 */}
                    <div className="recommendation-option" onClick={toggleDatabase}>
                        <div className="checkbox" >
                            {selectedDatabase && <div className="checkbox-ticked"></div>}
                        </div>
                        <div className="recommendation-description">
                            <div className="recommendation-title">Add a worry-free Managed Database (+$15.00)</div>
                            <div className="recommendation-info">Our scalable database cluster service includes daily backups with PITR, automated failover, and end-to-end SSL.</div>
                        </div>
                    </div>

                    <div className="advanced-options">
                        + Advanced Options
                    </div>
                </div>
                <hr className="section-separator" />

                <div className="droplet-config-container">
                    <div className="data-center-header">Finalize Details</div>
                    <div className="quantity-hostname-container">
                        <div className="droplet-quantity-container">
                            <div className="quantity-text">
                                <div className='quantity'>Quantity</div>
                                <div className='deploy'>Deploy multiple Droplets with the same configuration.</div>
                            </div>
                            <div className="quantity-control">
                                <div className='button btn-first' onClick={() => handleQuantityChange(-1)}>-</div>
                                <div className='droplet-count-text'>{quantity} Droplet</div>
                                <div className='button btn-last' onClick={() => handleQuantityChange(1)}>+</div>
                            </div>
                        </div>
                        <div className="hostname-container">
                            <div>Hostname</div>
                            <div>Give your Droplets an identifying name you will remember them by.</div>
                            <input
                                placeholder='my-hostname'
                                type="text"
                                value={hostname} onChange={(e) => setHostname(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div style={{ marginBottom: '7rem' }}>
                    <div className="additional-item">
                        <div>Tags</div>
                        <input
                            placeholder="Type tags here"
                            type="text"

                        />
                    </div>

                    <div className="additional-item">
                        <div>Project</div>
                        <input
                            defaultValue="SkyNet-prod"
                            type="text"

                        />
                    </div>
                </div>

                <div className="fixes-section">
                    <div className="selected-vm-price">
                        <div className="price-monthly">
                            {vmOptions[selectedVmOption - 1]?.priceMonthly.split('/')[0]}
                            <span className="price-monthly-unit">/month</span>
                        </div>
                        <div className="price-hourly">{vmOptions[selectedVmOption - 1]?.priceHourly}</div>
                    </div>

                    <div className='last-btns-section'>
                        <div className="create-via-command-line">CREATE VIA COMMAND LINE</div>

                        <div className="create-droplet-btn" onClick={handleSubmit}>
                            Create Droplet
                        </div>
                    </div>
                </div>


            </form>
        </div>
    );
};

export default CreateDropletPage;
