import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SignIn.scss';
import spotnetLogo from '../../assets/spotnet-logo-signin.png';
import googleLogo from '../../assets/google.svg';
import githubLogo from '../../assets/GitHub.svg';
import HeaderLogo from '../../assets/skynet.png'; 

const SignInPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        navigate('/env1'); 
    };

    return (
        <div>
            <div className="header-container">
                <div className="header-left">
                    <img src={HeaderLogo} alt='header-logo' />
                </div>
                <div className="header-right">
                    <span>Don't have an account? <a href="/signin" className="signup-link">Sign up</a></span>
                </div>
            </div>
        <div className="signin-container">
            <div className="signin-left">
                    <img src={spotnetLogo} alt="Spotnet Logo" className="background-image" /> 
                <div className='inner-container'>
                    <h1>Log in to your account</h1>
                    <form onSubmit={handleSubmit}>
                        <div className="input-container">
                            <label htmlFor="email">Email <span className="required">*</span></label>
                            <input
                                type="email"
                                id="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="input-container">
                            <label htmlFor="password">Password <span className="required">*</span></label>
                            <input
                                type="password"
                                id="password"
                                placeholder="Enter your password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                        </div>
                        <button type="submit" className="login-button">Log In</button>
                            <button type="button" className="oauth-button google" onClick={handleSubmit}>
                            <img src={googleLogo} alt="Google" />
                            <span>Sign In with Google</span>
                        </button>
                            <button type="button" className="oauth-button github" onClick={handleSubmit}>
                            <img src={githubLogo} alt="Github" />
                            <span>Sign In with Github</span>
                        </button>

                            <div className='forgot-pw'>Forgot password?</div>
                    </form>
                </div>
            </div>
            <div className="signin-right">
                <div className="promo-content">
                    <h2>Try SkyNet Managed Kafka today</h2>
                    <p>SkyNet Managed Kafka, a fully managed event streaming platform as a service, removes the complexities associated with self-managing Kafka and has an all-inclusive, flat-rate pricing model starting at just $147/month to help you avoid unexpected costs.</p>
                        <button className="more-info-button" onClick={handleSubmit}>Tell me more about Managed Kafka</button>
                </div>
            </div>
        </div>
        </div>
    );
};

export default SignInPage;
